
        <template>
          <div class="vc-snippet-doc">
            <h2>Container 布局容器</h2>
<p>用于布局的容器组件，方便快速搭建页面的基本结构</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"demo-container"</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-header</span>&gt;</span>Header<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-header</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-header</span>&gt;</span>Header<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-header</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-footer</span>&gt;</span>Footer<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-footer</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-aside</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"200px"</span>&gt;</span>Aside<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-aside</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-header</span>&gt;</span>Header<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-header</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-aside</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"200px"</span>&gt;</span>Aside<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-aside</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-header</span>&gt;</span>Header<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-header</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-aside</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"200px"</span>&gt;</span>Aside<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-aside</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">ixu-footer</span>&gt;</span>Footer<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-footer</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-aside</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"200px"</span>&gt;</span>Aside<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-aside</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-header</span>&gt;</span>Header<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-header</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-aside</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"200px"</span>&gt;</span>Aside<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-aside</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-container</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-header</span>&gt;</span>Header<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-header</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-main</span>&gt;</span>Main<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-main</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">ixu-footer</span>&gt;</span>Footer<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-footer</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-container</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">style</span> <span class="hljs-attr">lang</span>=<span class="hljs-string">"scss"</span>&gt;</span>
.demo-container{
   .ixu-header, .ixu-footer {
         background-color: #B3C0D1;
         color: #333;
         text-align: center;
         line-height: 60px;
       }
       
       .ixu-aside {
         background-color: #D3DCE6;
         color: #333;
         text-align: center;
         line-height: 200px;
       }
       
       .ixu-main {
         background-color: #E9EEF3;
         color: #333;
         text-align: center;
         line-height: 160px;
       }
       
       body &gt; .ixu-container {
         margin-bottom: 40px;
       }
       
       .ixu-container:nth-child(5) .ixu-aside,
       .ixu-container:nth-child(6) .ixu-aside {
         line-height: 260px;
       }
       
       .ixu-container:nth-child(7) .ixu-aside {
         line-height: 320px;
       }
}
<span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Container Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>direction</td>
<td>子元素的排列方向</td>
<td>string</td>
<td>horizontal / vertical</td>
<td>子元素中有 ixu-header 或 ixu-footer 时为 vertical，否则为 horizontal</td>
</tr>
</tbody>
</table>
<h3>Header Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>height</td>
<td>顶栏高度</td>
<td>string</td>
<td>—</td>
<td>60px</td>
</tr>
</tbody>
</table>
<h3>Aside Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>width</td>
<td>侧边栏宽度</td>
<td>string</td>
<td>—</td>
<td>300px</td>
</tr>
</tbody>
</table>
<h3>Footer Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>height</td>
<td>底栏高度</td>
<td>string</td>
<td>—</td>
<td>60px</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"demo-container"},[_c('ixu-container',[_c('ixu-header',[_vm._v("Header")]),_vm._v(" "),_c('ixu-main',[_vm._v("Main")])],1),_vm._v(" "),_c('ixu-container',[_c('ixu-header',[_vm._v("Header")]),_vm._v(" "),_c('ixu-main',[_vm._v("Main")]),_vm._v(" "),_c('ixu-footer',[_vm._v("Footer")])],1),_vm._v(" "),_c('ixu-container',[_c('ixu-aside',{attrs:{"width":"200px"}},[_vm._v("Aside")]),_vm._v(" "),_c('ixu-main',[_vm._v("Main")])],1),_vm._v(" "),_c('ixu-container',[_c('ixu-header',[_vm._v("Header")]),_vm._v(" "),_c('ixu-container',[_c('ixu-aside',{attrs:{"width":"200px"}},[_vm._v("Aside")]),_vm._v(" "),_c('ixu-main',[_vm._v("Main")])],1)],1),_vm._v(" "),_c('ixu-container',[_c('ixu-header',[_vm._v("Header")]),_vm._v(" "),_c('ixu-container',[_c('ixu-aside',{attrs:{"width":"200px"}},[_vm._v("Aside")]),_vm._v(" "),_c('ixu-container',[_c('ixu-main',[_vm._v("Main")]),_vm._v(" "),_c('ixu-footer',[_vm._v("Footer")])],1)],1)],1),_vm._v(" "),_c('ixu-container',[_c('ixu-aside',{attrs:{"width":"200px"}},[_vm._v("Aside")]),_vm._v(" "),_c('ixu-container',[_c('ixu-header',[_vm._v("Header")]),_vm._v(" "),_c('ixu-main',[_vm._v("Main")])],1)],1),_vm._v(" "),_c('ixu-container',[_c('ixu-aside',{attrs:{"width":"200px"}},[_vm._v("Aside")]),_vm._v(" "),_c('ixu-container',[_c('ixu-header',[_vm._v("Header")]),_vm._v(" "),_c('ixu-main',[_vm._v("Main")]),_vm._v(" "),_c('ixu-footer',[_vm._v("Footer")])],1)],1)],1)}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         























































.demo-container{
   .ixu-header, .ixu-footer {
         background-color: #B3C0D1;
         color: #333;
         text-align: center;
         line-height: 60px;
       }
       
       .ixu-aside {
         background-color: #D3DCE6;
         color: #333;
         text-align: center;
         line-height: 200px;
       }
       
       .ixu-main {
         background-color: #E9EEF3;
         color: #333;
         text-align: center;
         line-height: 160px;
       }
       
       body > .ixu-container {
         margin-bottom: 40px;
       }
       
       .ixu-container:nth-child(5) .ixu-aside,
       .ixu-container:nth-child(6) .ixu-aside {
         line-height: 260px;
       }
       
       .ixu-container:nth-child(7) .ixu-aside {
         line-height: 320px;
       }
}

       </style>